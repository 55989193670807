<template>
  <b-card
    no-body
    class="card-company-table"
  >
    <!-- <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      &lt;!&ndash; company &ndash;&gt;
      <template #cell(company)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            rounded
            size="32"
            variant="light-company"
          >
            <b-img
              :src="data.item.avatarImg"
              alt="avatar img"
            /></b-avatar>
          <div>
            <div class="font-weight-bolder">
              {{ data.item.title }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.subtitle }}
            </div>
          </div>
        </div>
      </template>

      &lt;!&ndash; category &ndash;&gt;
      <template #cell(category)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            class="mr-1"
            :variant="data.item.avatarColor"
          >
            <feather-icon
              size="18"
              :icon="data.item.avatarIcon"
            />
          </b-avatar>
          <span>{{ data.item.avatarTitle }}</span>
        </div>
      </template>

      &lt;!&ndash; views &ndash;&gt;
      <template #cell(views)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.viewTitle }}</span>
          <span class="font-small-2 text-muted text-nowrap">{{ data.item.viewsub }}</span>
        </div>
      </template>

      &lt;!&ndash; revenue &ndash;&gt;
      <template #cell(revenue)="data">
        {{ '$'+data.item.revenue }}
      </template>

      &lt;!&ndash; sales &ndash;&gt;
      <template #cell(sales)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.sales+'%' }}</span>
          <feather-icon
            :icon="data.item.loss ? 'TrendingDownIcon':'TrendingUpIcon'"
            :class="data.item.loss ? 'text-danger':'text-success'"
          />
        </div>
      </template>
    </b-table> -->
  </b-card>
</template>

<script>
import {
  BCard, /* BTable, BAvatar, BImg, */
} from 'bootstrap-vue'
import dbStats from '@/models/stats'

export default {
  components: {
    BCard,
    /* BTable,
    BAvatar,
    BImg, */
    // eslint-disable-next-line vue/no-unused-components
    dbStats,
  },

  data() {
    return {
      fields: [
        { key: 'mechanic', label: 'COMPANY' },
        { key: 'category', label: 'CATEGORY' },
        { key: 'views', label: 'VIEWS' },
        { key: 'revenue', label: 'REVENUE' },
        { key: 'sales', label: 'SALES' },
      ],
      data: [],
      startDate: null,
      endDate: null,
      filterStatus: '',
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const response = await dbStats.statsBudgetsMechanics({
        start_date: this.startDate,
        end_date: this.endDate,
        status: this.filterStatus,
      })
      this.data = response
    },
  },
}

</script>

<style lang="scss" scoped>
    @import '~@core/scss/base/bootstrap-extended/include';
    @import '~@core/scss/base/components/variables-dark';

    .card-company-table ::v-deep td .b-avatar.badge-light-company {
        .dark-layout & {
            background: $theme-dark-body-bg !important;
        }
    }
</style>
