import api from '@/api/index'

export default {
  getAll: async payload => {
    const response = await api.finances.getAll(payload)
    const { data } = response
    return data
  },
  get: async payload => {
    const response = await api.finances.get(payload)
    const { data } = response
    return data
  },
  save: async payload => {
    const response = await api.finances.save(payload)
    return response
  },
  destroy: async payload => {
    const response = await api.finances.destroy(payload)
    return response
  },
}
